@import '../../styles/mixins';
@import '../../styles/variables';

.NoSubscription {
  width: 312px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 96px auto;

  &__text {
    margin: 0 0 24px 0;
  
    @include typography(normal, bold, 24px, 134%, $color-text);

    text-align: center;
  }

  &__button-subscription {
    display: block;
    width: 100%;
    margin: 0 0 16px 0;
    padding: 20px 45px;

    @include input($color-button-buy-background, 0, $color-button-buy-background, 12px);

    @include typography(normal, 600, 16px, 100%, $color-button-buy-text);

    text-decoration: none;
    text-align: center;

    box-shadow: 0px 6px 12px rgba(0, 102, 255, 0.3);

    @include on-hover-scale(1.01);
  }

  &__button-logout {
    width: 100%;
    padding: 20px 45px;

    @include input(rgba(0, 0, 0, 0.05), 0, rgba(0, 0, 0, 0.05), 12px);

    @include typography(normal, 600, 16px, 100%, $color-text);

    text-decoration: none;

    @include on-hover-scale(1.01);
  }
}
