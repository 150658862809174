@import '../../styles/animations';
@import '../../styles/mixins';
@import '../../styles/variables';

.BookPreview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 32px 0;

  cursor: pointer;

  &__placeholder {
    width: 100%;
    height: 100%;
    margin: 0 0 10px 0;

    border-radius: 8px;

    background: #0000001A;

    z-index: $z-index-book-preview-placeholder;

    filter: drop-shadow(0px 8px 14px rgba(0, 0, 0, 0.1));

    background: linear-gradient(125deg, #FFFFFF4D, #0000001A);
    background-size: 400% 400%;

    animation: gradient 15s ease infinite;

    &-active {
      background: linear-gradient(125deg, #FFFFFF4D, #0000001A);
      background-size: 400% 400%;

      animation: gradient 15s ease infinite;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    border-radius: 8px;

    z-index: $z-index-book-preview-image;

    opacity: 0;

    @include on-hover-scale(1.08);

    &-loaded {
      animation: fadeIn 0.7s both;
    }
  }

  &__author-wrapper {
    position: relative;
    width: 148px;
  }

  &__author {
    width: 100%;

    @include typography(normal, normal, 14px, 146%, $color-text);

    overflow: hidden;
    white-space: nowrap;  
    text-overflow: clip;
  }

  &__gradient-block {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 20px;

    z-index: $z-index-book-preview-gradient-block;

    background: linear-gradient(270deg, $color-background-main 0%, rgba(255, 248, 244, 0) 100%);
  }
}

@media (min-width: 1000px) {
  .BookPreview {
    &__author-wrapper {
      width: 100%;
    }
  }
}
