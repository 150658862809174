@import '../../styles/mixins';
@import '../../styles/variables';

.SignIn {
  height: 100%;

  background-color: $color-background-main;

  &__grid {
    @include grid-two-columns-scalable;
  
    margin: 0 32px;
    padding: 32px 0 32px 0;
  }

  &__icon {
    grid-column: 1 / 3;
    width: 100px;
    height: 100px;
    margin: 0 0 16px 0;

    background: url('../../assets/images/LoginPageTop.svg');
  }

  &__title {
    grid-column: 1 / 3;
    margin: 0 0 16px 0;

    @include typography(normal, bold, 32px, 130%, $color-text);

    &--accent {
      color: $color-text-accents;
    }
  }

  &__error-wrapper {
    grid-column: 1 / 3;
    margin: 16px 0 0 0;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-error-message-background;

    border-radius: 12px;
  }

  &__error {
    @include typography(normal, normal, 16px, 130%, $color-error-message);
  }

  &__text {
    grid-column: 1 / 3;
    margin: 0 0 24px 0;
  
    @include typography(normal, normal, 14px, 146%, $color-text-login);
  }

  &__text--login-choice {
    grid-column: 1 / 3;
    margin: 16px 0;
  
    @include typography(normal, normal, 16px, 150%, $color-text);

    text-align: center;
  }
}

@media (min-width: 524px) {
  .SignIn {
    display: flex;
    justify-content: center;
    align-items: center;

    &__grid {
      grid-template-columns: repeat(2, 222px);
    
      margin: 0 auto;
      padding: 0;
    }

    &__title {
      margin: 0 0 16px;

      //text-align: center;
    }
  }
}
