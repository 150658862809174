@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Inter', sans-serif;
}

html,
body,
#root {
  height: 100%;
}
