@import '../../styles/mixins';
@import '../../styles/variables';

.BookSummary {
  overflow-x: hidden;
  overflow-y: scroll;

  &__background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;

    z-index: -2;

    background-color: $color-background-main;
  }

  &__progress-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px; 
    
    z-index: 999 
  }

  &__header {
    @include header-bar(60px);

    padding: 0 32px;
  }

  &__header-content {
    position: relative;
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    padding: 0 40px 0 40px;

    @include typography(normal, bold, 20px, 138%, $color-text);

    overflow-x: hidden;
    white-space: nowrap;
  }

  &__icon-back {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;

    z-index: 2;

    background-image: url('../../assets/icons/arrow-left.svg');

    cursor: pointer;

    @include on-hover-scale(1.2);
  }

  &__gradient-block {
    position: absolute;
    right: 0;
    width: 40px;
    height: 20px;

    z-index: 15;

    background: linear-gradient(270deg, $color-background-main 0%, rgba(255, 248, 244, 0) 100%);
  }

  &__grid {
    @include grid-two-columns-scalable;

    row-gap: 40px;
    column-gap: 16px;
    margin: 72px 32px 28px;
  }
}

@media (min-width: 1000px) {
  .BookSummary {
    &__grid {
      grid-template-columns: repeat(2, 460px);
    }

    &__header {
      grid-template-columns: repeat(2, 460px);
    }
  }
}
