@import '../../styles/mixins';
@import '../../styles/variables';

.Error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 96px 0 0 0;

  &__illustration {
    width: 375px;
    height: 280px;
    margin: 0 0 24px 0;

    background-image: url('../../assets/images/ErrorIllustration.svg');
  }

  &__text {
    margin: 0 0 12px 0;

    @include typography(normal, bold, 24px, 134%, $color-text);
  }

  &__text-secondary {
    @include typography(normal, normal, 16px, 150%, $color-text);

    opacity: 0.8;
  }
}
