@import '../../styles/mixins';
@import '../../styles/variables';

.SearchBar {
  @include header-bar(56px);

  padding: 0 32px;

  &__input-wrapper {
    grid-column: 1 / 3;
  }

  &__icon-back {
    position: absolute;
    top: 16px;
    width: 24px;
    height: 24px;

    z-index: 2;

    background-image: url('../../assets/icons/arrow-left.svg');

    cursor: pointer;

    @include on-hover-scale(1.2);
  }

  &__input {
    width: 100%;
    padding: 16px 0 16px 40px;

    border: 0;
    background-color: $color-background-main;

    @include typography(normal, normal, 16px, 150%, $color-text);

    &::placeholder {
      @include typography(normal, normal, 16px, 150%, $color-text);

      opacity: 0.4;
    }

    &::-webkit-search-cancel-button {
      display: none;
    }
  }
}

@media (min-width: 1000px) {
  .SearchBar {
    grid-template-columns: repeat(2, 460px);
  }
}
