@import '../../styles/animations';
@import '../../styles/mixins';
@import '../../styles/variables';

.LoadingAnimation {
  position: fixed;
  top: calc(50% - 28px);
  left: calc(50% - 28px);

  &__spinner {
    width: 56px;
    height: 56px;

    animation: rotate 2s linear infinite;

    z-index: 900;

    &-path {
      stroke: #0066FF;
      stroke-linecap: round;

      animation: dash 2s ease-in-out infinite;
    }
  }
}
