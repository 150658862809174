@import '../../styles/mixins';
@import '../../styles/variables';

.PageDivider {
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 16px;
  align-items: center;

  &__line {
    width: 100%;
    height: 1px;

    background-color: $color-text;
  
    opacity: 0.8;
  }

  &__text {
    @include typography(normal, normal, 16px, 150%, $color-text);

    opacity: 0.8;
  }
}
