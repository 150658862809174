@mixin input($background-color, $width, $border-color, $radius) {
  background-color: $background-color;
  border: $width solid $border-color;
  border-radius: $radius;
}

@mixin typography($style, $weight, $size, $line-height, $color) {
  font-style: $style;
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;

  color: $color;
}

@mixin on-hover-scale($scale) {
  transition: 0.3s transform;

  &:hover {
    transform: scale($scale);
  }
}

@mixin grid-two-columns-scalable {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;
  justify-content: center;
}

@mixin header-bar($height) {
  position: fixed;

  @include grid-two-columns-scalable;

  width: 100%;
  height: $height;

  z-index: $z-index-header-bar;

  background-color: $color-background-main;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
}
