@import '../../styles/mixins';
@import '../../styles/variables';

.Discover {
  overflow-x: hidden;
  overflow-y: scroll;

  &__header {
    position: fixed;
    width: 100%;
    height: 74px;

    @include grid-two-columns-scalable;

    padding: 0 32px;

    z-index: $z-index-header-bar;

    &--small {
      @include header-bar(56px);

      padding: 0 32px;
    }
  }

  &__title-wrapper {
    grid-column: 1 / 3;
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    @include typography(normal, bold, 32px, 130%, $color-text);

    &--small {
      @include typography(normal, bold, 20px, 138%, $color-text);
    }
  }

  &__icon-search {
    width: 24px;
    height: 24px;

    background-image: url('../../assets/icons/search.svg');

    cursor: pointer;

    @include on-hover-scale(1.2);
  }

  &__background {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;

    z-index: -2;

    background-color: $color-background-main;
  }

  &__button-open-settings {
    position: fixed;
    bottom: 24px;
    right: 32px;
    width: 56px;
    padding: 16px;

    background-color: $color-button-settings;
    border-radius: 22px;
    border: 0;

    filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.08));

    z-index: 20;

    @include on-hover-scale(1.05);
  }

  &__icon-open-settings {
    width: 24px;
    height: 24px;

    background-image: url('../../assets/icons/equalizer.svg');
  }
}

@media (min-width: 1000px) {
  .Discover {
    &__header {
      grid-template-columns: repeat(2, 460px);

      margin: 0 auto;

      &--small {
        @include header-bar(56px);
  
        grid-template-columns: repeat(2, 460px);
      }
    }

    &__button-open-settings {
      left: calc(50% + 412px);
    }
  }
}
