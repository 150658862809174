@import '../../styles/mixins';
@import '../../styles/variables';

.Settings {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  background-color: $color-background-main;

  &__header {
    @include header-bar(56px);
  }

  &__header-content {
    position: relative;
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    
    margin: 0 32px;
  }

  &__icon-back {
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;

    background-image: url('../../assets/icons/arrow-left.svg');

    cursor: pointer;

    @include on-hover-scale(1.2);
  }

  &__title {
    padding: 0 40px 0 40px;

    @include typography(normal, bold, 20px, 138%, $color-text);
  }

  &__grid {
    @include grid-two-columns-scalable;

    row-gap: 24px;
    margin: 70px 32px 0;
  }

  &__link {
    grid-column: 1 / 3;

    @include typography(normal, normal, 16px, 150%, $color-text);

    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logout {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
  }

  &__logout-link {
    @include typography(normal, normal, 16px, 150%, $color-text);

    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }
  }

  &__logout-email {
    @include typography(normal, normal, 12px, 134%, $color-text);

    opacity: 0.4;
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 24px;

    @include grid-two-columns-scalable;

    margin: 0 32px;
  }

  &__stores {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__google-play {
    height: 47px;
    width: 156px;
  
    background-image: url('../../assets/icons/ads-google.svg');
  }

  &__app-store {
    height: 47px;
    width: 139px;
  
    background-image: url('../../assets/icons/ads-apple.svg');
  }
}

@media (min-width: 1000px) {
  .Settings {
    &__header {
      grid-template-columns: repeat(2, 460px);
    }

    &__grid {
      grid-template-columns: repeat(2, 460px);
      
      margin: 70px auto 0;
    }
  
    &__footer {
      grid-template-columns: repeat(2, 460px);

      margin: 0 auto;
    }

    &__header-content {
      margin: 0;
    }
  
  }
}
