@import '../../styles/mixins';
@import '../../styles/variables';

.BooksList {
  &__no-books-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin: 96px 0 0 0;
  }

  &__no-books-icon {
    width: 72px;
    height: 72px;

    background-image: url('../../assets/icons/search-big.svg');
  }

  &__no-books-text {
    @include typography(normal, bold, 24px, 134%, $color-text);

    opacity: 0.4;
  }

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    column-gap: 16px;
    justify-content: center;

    margin: 0 32px;
  }
}

.infinite-scroll-component {
  padding: 88px 0 0 0;
}

@media (min-width: 540px) {
  .BooksList {
    &__grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media (min-width: 704px) {
  .BooksList {
    &__grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (min-width: 1000px) {
  .BooksList {
    &__grid {
      grid-template-columns: repeat(4, 222px);

      margin: 0 auto;
    }
  }
}
