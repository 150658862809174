@import '../../styles/mixins';
@import '../../styles/variables';

@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.BookSummaryPage {
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  &__title-wrapper {
    width: 100%;
  }

  &__title {
    @include typography(normal, bold, 24px, 134%, $color-text);
  }

  &__text-wrapper {
    width: 100%;
  }

  &__text {
    font-family: 'Alegreya', serif;
  
    @include typography(normal, normal, 20px, 27px, $color-text);

    opacity: 0.8;
  }

  &__insight-wrapper {
    display: grid;
    grid-template-columns: 20px 284px;
    column-gap: 8px;
    
    @include on-hover-scale(1.009);
  }

  &__insight-icon {
    width: 20px;
    height: 20px;
    margin: 4px 0 0 0;

    background-image: url('../../assets/icons/flash.svg');

    color: #0066FF;
  }

  &__insight {
    @include typography(normal, normal, 16px, 150%, $color-text);

    opacity: 0.8;
  }

  &__quote-wrapper {
    display: grid;
    grid-template-columns: 20px 284px;
    column-gap: 8px;
    row-gap: 12px;
  }

  &__quote-icon {
    width: 20px;
    height: 20px;

    background-image: url('../../assets/icons/quotes.svg');
  }

  &__quote {
    @include typography(normal, normal, 16px, 150%, $color-text);

    opacity: 0.8;
  }

  &__quote-author {
    grid-column: 2 / 3;

    @include typography(normal, normal, 14px, 146%, $color-text);

    opacity: 0.4;
  }
}
