@import '../../styles/mixins';
@import '../../styles/variables';

.SignInEmail {
  grid-column: 1 / 3;

  &__title {
    margin: 0 0 16px 0;
  
    @include typography(normal, bold, 20px, 138%, $color-text);

    text-align: center;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  &__input {
    width:100%;
    height: 48px;
  
    padding: 12px 0 12px 44px;
  
    @include input(rgba(0, 0, 0, 0.05), 1px, rgba(0, 0, 0, 0.1), 10px);

    &-icon {
      position: absolute;
  
      width: 24px;
      height: 24px;
  
      &--email {
        top: 12px;
        left: 12px;
    
        background-image: url('../../assets/icons/email-grey.svg');

        &-error {
          background-image: url('../../assets/icons/email-red.svg');
        }
      }
  
      &--password {
        top: 12px;
        left: 12px;
    
        background-image: url('../../assets/icons/password-lock-grey.svg');

        &-error {
          background-image: url('../../assets/icons/password-lock-red.svg');
        }
      }
    }
  
    &::placeholder {
      @include typography(normal, normal, 16px, 150%, rgba(0, 0, 0, 0.4));
    }

    &:focus {
      outline: none;

      background-color: $color-input-focused-background;

      border: 1px solid $color-input-focused-border;
    }

    &:focus + .SignInEmail__input-icon--email {
      background-image: url('../../assets/icons/email.svg');
    }

    &:focus + .SignInEmail__input-icon--password {
      background-image: url('../../assets/icons/password-lock.svg');
    }

    &-error {
      background-color: $color-input-error-background;

      border-color: $color-input-error-border;

      &:focus {
        outline: none;
  
        background-color: $color-input-error-background;

        border-color: $color-input-error-border;
      }

      &:focus + .SignInEmail__input-icon--email-error {
        background-image: url('../../assets/icons/email-red.svg');
      }
  
      &:focus + .SignInEmail__input-icon--password-error {
        background-image: url('../../assets/icons/password-lock-red.svg');
      }
  
    }
  }

  &__error-message {
    margin: 0 0 4px 0;

    @include typography(normal, normal, 14px, 140%, $color-error-message);
  }

  &__input-wrapper {
    position: relative;
  }

  &__agreement {
    @include typography(normal, normal, 12px, 134%, rgba(0, 0, 0, 0.4));

    text-align: center;

    &-link {
      @include typography(normal, normal, 12px, 134%, rgba(0, 0, 0, 0.4));

      &:hover {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  &__button {
    padding: 20px 120.5px;
  
    @include input($color-button-login, 0, $color-button-login, 12px);

    @include typography(normal, 600, 16px, 100%, $color-button-login-text);

    box-shadow: 0px 6px 12px rgba(0, 102, 255, 0.3);

    &--disabled {
      @include input(rgba(0, 0, 0, 0.05), 0, rgba(0, 0, 0, 0.1), 12px);

      @include typography(normal, 600, 16px, 100%, $color-button-login-text-disabled);

      box-shadow: 0 0 0;
    }

    @include on-hover-scale(1.005);

    cursor: pointer;
  }
}
