@import '../../styles/mixins';
@import '../../styles/variables';

.BookOverview {
  position: fixed;
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: scroll;

  background-color: $color-background-main;

  &__grid {
    @include grid-two-columns-scalable;
  
    padding: 0 32px;
  
    row-gap: 24px;
    justify-content: center;
  }
  
  &__info {
    position: relative;
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 24px 0 0 0;
  }

  &__cover {
    width: 184px;
    height: 276px;
    margin: 0 0 16px 0;

    filter: drop-shadow(0 8px 14px rgba(0, 0, 0, 0.1));
    border-radius: 10px;
  }

  &__book-title {
    margin: 0 0 8px 0;
  
    @include typography(normal, bold, 24px, 134%, $color-text);
  }

  &__book-author {
    margin: 0 0 16px 0;
  
    @include typography(normal, normal, 16px, 150%, $color-text);

    mix-blend-mode: normal;
    opacity: 0.4;
  }

  &__briefs {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  &__brief {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 4px;

    &--center {
      justify-self: center;
    }

    &-icon {
      width: 20px;
      height: 20px;

      &--time {
        background-image: url('../../assets/icons/clock.svg');
      }

      &--chapters {
        background-image: url('../../assets/icons/list.svg')
      }

      &--insights {
        background-image: url('../../assets/icons/flash-grey.svg')
      }
    }

    &-text {
      @include typography(normal, normal, 14px, 146%, $color-text);
    }
  }

  &__divider {
    grid-column: 1 / 3;
    width: 100%;
    height: 1px;

    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &__button-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;

    background-color: rgba(232, 221, 216, 0.6);
    backdrop-filter: blur(40px);
    border: 0;
    border-radius: 10px;

    cursor: pointer;

    @include on-hover-scale(1.1);
  }

  &__icon-close {
    position: absolute;
    top: 4px;
    left: 4px;

    width: 20px;
    height: 20px;

    background-image: url('../../assets/icons/cross.svg');
  }

  &__keywords-wrapper {
    grid-column: 1 / 3;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__keyword {
    padding: 6px 10px;

    background-color: rgba(0, 0, 0, 0.045);
    border-radius: 14px;

    &-text {
      @include typography(normal, normal, 12px, 134%, $color-text);

      mix-blend-mode: normal;
      opacity: 0.6;
    }
  }

  &__description-wrapper {
    grid-column: 1 / 3;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 0 100px 0;
  }

  &__description-title {
    @include typography(normal, bold, 20px, 138%, $color-text);
  }

  &__description {
    @include typography(normal, normal, 16px, 150%, $color-text);
  }

  &__button-read {
    position: fixed;
    width: calc(100% - 64px);
    bottom: 16px;
    padding: 20px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @include typography(normal, 600, 16px, 100%, $color-button-read-text);

    background-color: $color-button-read-background;
    box-shadow: 0px 6px 12px $color-button-read-shadow;
    border: 0;
    border-radius: 12px;

    cursor: pointer;

    @include on-hover-scale(1.01);
  }

  &__icon-read {
    width: 24px;
    height: 24px;

    background-image: url('../../assets/icons/text.svg');
  }
}

@media (min-width: 1000px) {
  .BookOverview {
    &__grid {
      grid-template-columns: repeat(2, 460px);
    }

    &__button-read {
      width: 936px;
      left: calc(50% - 936px / 2);
    }
  }
}
