$color-background-main: #FFF8F4;

$color-button-apple-background: #000000;
$color-button-apple-border: #000000;
$color-button-apple-text: #FFFFFF;
$color-button-apple-shadow: rgba(0, 0, 0, 0.3);
$color-button-google-background: #0066FF;
$color-button-google-border: #0066FF;
$color-button-google-text: #FFFFFF;
$color-button-google-shadow: rgba(0, 102, 255, 0.3);
$color-button-read-background: #0066FF;
$color-button-read-border: #0066FF;
$color-button-read-text: #FFFFFF;
$color-button-read-shadow: rgba(0, 102, 255, 0.3);
$color-button-background: black;
$color-button-border: black;
$color-button-text: black;
$color-button-settings: #FFFFFF;
$color-button-login: #0066FF;
$color-button-login-text: #FFFFFF;
$color-button-login-text-disabled: #000000;

$color-button-buy-background: #0066FF;
$color-button-buy-text: #FFFFFF;

$color-error-message: #FF3333;
$color-error-message-background: #FF33331A;

$color-input-background: black;
$color-input-border: black;
$color-input-placeholder: black;
$color-input-text: black;
$color-input-focused-background: #0066FF1A;
$color-input-focused-border: #0066FF;
$color-input-error-background: #FF33331A;
$color-input-error-border: #FF3333;

$color-text: #000000;
$color-text-accents: #0066FF;
$color-text-login: rgba(0, 0, 0, 0.6);
$color-text-secondary: #000000;

$z-index-header-bar: 10;
$z-index-book-preview-image: 5;
$z-index-book-preview-placeholder: 4;
$z-index-book-preview-gradient-block: 2;
