@import '../../styles/mixins';
@import '../../styles/variables';

.SignInApple {
  position: relative;

  &__button {
    width: 100%;
    padding: 16px 28px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
  
    @include input($color-button-apple-background, 0, $color-button-apple-border, 12px);

    box-shadow: 0px 6px 12px $color-button-apple-shadow;

    @include on-hover-scale(1.01);

    cursor: pointer;
  }

  &__icon {
    width: 24px;
    height: 24px;

    background-image: url('../../assets/icons/apple.svg');
  }

  &__text {
    @include typography(normal, 600, 16px, 100%, $color-button-apple-text);
  }
}
